import './styles.css';

function Hero() {
  return (
    <div className="hero">
      $BCC the Baby Coconut Chicken token on <span className='red_font'>TRON inspired by Justin Sun</span>
    </div>
  );
}

export default Hero;
