import React, { useEffect } from 'react';
import AOS from 'aos';

import 'aos/dist/aos.css';
import './styles.css';

import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'

function Token() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id='token' className="token">
      <div>
        <img
          className='icon-1'
          src={icon1} 
          alt={'babycoconutchicken'} 
          data-aos="fade-up"
          data-aos-delay="100"  
        /> 
        <h3>LP burned</h3>       
      </div>
      <div>
        <img
          className='icon-2'
          src={icon2} 
          alt={'babycoconutchicken'}
          data-aos="zoom-out"
          data-aos-delay="200"
        />
        <h3>Contract renounced</h3>
      </div>
      <div>
        <img
          className='icon-3'
          src={icon3} 
          alt={'babycoconutchicken'}
          data-aos="fade-down"
          data-aos-delay="300"
        />
        <h3>Community token</h3>
      </div>
    </div>
  );
}

export default Token;
