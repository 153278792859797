import React from 'react';
import { useSpring, animated } from 'react-spring';
import './styles.css';
import logo from './img/logo-name.png'
import header_hero_1 from './img/Spread-some-love.png'
import header_hero_2 from './img/For-a-small-coconut-chicken.png'
import header_chicken_1 from './img/header-chicken1.png'
import header_chicken_2 from './img/header-chicken2.png'
import header_chicken_3 from './img/header-chicken3.png'
import Navigation from './components/Navigation/Navigation'

function Header() {
  const [props, set] = useSpring(() => ({
    xy: [0, 0], // domyślne wartości dla ruchu myszki
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans = (x, y) => `translate3d(${x / 83}px,${y / 83}px,0)`;
  const trans2 = (x, y) => `translate3d(${x / 45}px,${y / 45}px,0)`;
  const trans3 = (x, y) => `translate3d(${x / 80}px,${y / 80}px,0)`;

  return (
    <div className='header'>
      <div className='navbar'>
        <div className='logo'>
          <img src={logo} alt="baby-coconut-chicken" />
        </div>
        <Navigation/>
      </div>
      <div className='hero-header'>
        <img className='hero-header-1' src={header_hero_1} alt="spread-some-love"/>
        <img className='hero-header-2' src={header_hero_2} alt="for-a-small-coconut-chicken"/>
      </div>
      <div className='hero-hero'    
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
        style={{ width: '100%', height: '100vh', overflow: 'hidden' }}
      >
        <animated.img src={header_chicken_1} className='hero-img-1' alt="header_chicken_1" style={{zIndex: 0, transform: props.xy.interpolate(trans)}}/>
        <animated.img src={header_chicken_2} className='hero-img-2' alt="header_chicken_2" style={{zIndex: 1, transform: props.xy.interpolate(trans2)}}/>
        <animated.img src={header_chicken_3} className='hero-img-3' alt="header_chicken_3" style={{zIndex: 0, transform: props.xy.interpolate(trans3)}}/>
      </div>
    </div>
  );
}

export default Header;
