import React, { useEffect } from 'react';
import AOS from 'aos';
import { useSpring, animated } from 'react-spring';

import './styles.css';
import chicken from './img/chicken3.png'
// import yt from './img/yt.png'
import tw from './img/tw.png'
import tele from './img/tele.png'
// import tt from './img/tt.png'
import logo from './img/logo.png'

function Footer() {

  // const yotube_redirect = () => {
  //   window.open('https://youtube.com/', '_blank');
  // };
  const tweeter_redirect = () => {
    window.open('https://twitter.com/CocoBabyChick', '_blank');
  };
  const telegram_redirect = () => {
    window.open('https://t.me/BabyCoconutChickenChat', '_blank');
  };
  // const tiktok_redirect = () => {
  //   window.open('https://www.tiktok.com/', '_blank');
  // };

  const [props, set] = useSpring(() => ({
    xy: [0, 0], // domyślne wartości dla ruchu myszki
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0)`;

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id='footer' className="footer" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <h3 data-aos="fade-up" data-aos-delay="0">FIND US ON!</h3>
      <h4 data-aos="fade-up" data-aos-delay="100">
        <a href='mailto:team@babycoconutchicken.com'>team@babycoconutchicken.com</a>
      </h4>
      <div data-aos="fade-up" data-aos-delay="200">
        {/* <button onClick={yotube_redirect}>
          <img src={yt} alt='youtube'/>
        </button> */}
        <button onClick={tweeter_redirect}>
          <img src={tw} alt='tweeter'/>
        </button>
        <button onClick={telegram_redirect}>
          <img src={tele} alt='telegram'/>
        </button>
        {/* <button onClick={tiktok_redirect}>
          <img src={tt} alt='tiktok'/>
        </button> */}
      </div>
      <img src={logo} alt='babycoconutchicken' width={100}/>

      <animated.img 
        style={{ transform: props.xy.interpolate(trans) }} 
        className='footer-chicken' 
        src={chicken} 
        alt='babycoconutchicken'
      />
    </div>
  );
}

export default Footer;
