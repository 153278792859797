import './styles.css';
// import youtube from './img/yt.png'
import tweeter from './img/tw.png'
import telegram from './img/tele.png'
// import tiktok from './img/tt.png'

function Navigation() {

  // const yotube_redirect = () => {
  //   window.open('https://youtube.com/', '_blank');
  // };
  const tweeter_redirect = () => {
    window.open('https://twitter.com/CocoBabyChick', '_blank');
  };
  const telegram_redirect = () => {
    window.open('https://t.me/BabyCoconutChickenChat', '_blank');
  };
  // const tiktok_redirect = () => {
  //   window.open('https://www.tiktok.com/', '_blank');
  // };

  return (
    <div className='nav'>
      <div></div>
      <ul className='page-navigation'>
        <li>
          <a href='#start'>Start</a>
        </li>
        <li>
          <a href='#token'>Token details</a>
        </li>
        <li>
          <a href='#charts'>Charts</a>
        </li>
        <li>
          <a href='#howtobuy'>How to buy</a>
        </li>
        <li>
          <a href='#footer'>Contact</a>
        </li>
      </ul>
      <ul className='social'>
        {/* <li>
          <button onClick={yotube_redirect}>
            <img src={youtube} alt='youtube'/>
          </button>
        </li> */}
        <li>
          <button onClick={tweeter_redirect}>
            <img src={tweeter} alt='tweeter'/>
          </button>
        </li>
        <li>
          <button onClick={telegram_redirect}>
            <img src={telegram} alt='telegram'/>
          </button>    
        </li>
        {/* <li>
          <button onClick={tiktok_redirect}>
            <img src={tiktok} alt='tiktok'/>
          </button>
        </li> */}
      </ul>
    </div>
  );
}

export default Navigation;
