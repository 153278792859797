import React, { useEffect } from 'react';
import AOS from 'aos';
import { useSpring, animated } from 'react-spring';

import './styles.css';
import ufo from './img/ufo.png'
import chicken from './img/chicken.png'
import tronscan from './img/tronscan.png'
import avedex from './img/avedex.png'
import dex from './img/dex.png'
import more from './img/more.png'


function Available() {

  const tronscan_redirect = () => {
    window.open('https://tronscan.org/#/token20/TXZUmRx4T1RW2Uj1GeTmJWyx98R9XAS2sn', '_blank');
  };

  const avedex_redirect = () => {
    window.open('https://ave.ai/token/TXZUmRx4T1RW2Uj1GeTmJWyx98R9XAS2sn-tron?from=Token', '_blank');
  };

  const dex_redirect = () => {
    window.open('https://www.dextools.io/app/en/tron/pair-explorer/THCnUNbhENq5yBfVvQyey77Fr6yrxYST5b?t=1722439514712', '_blank');
  };


  const [props, set] = useSpring(() => ({
    xy: [0, 0], // domyślne wartości dla ruchu myszki
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`;
  const trans2 = (x, y) => `translate3d(${x / 40}px,${y / 40}px,0)`;


  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id='charts' className="available">
      <div className='content'>
        <div className='info'>
          <h2 data-aos="fade-up" data-aos-delay="0">Available <span style={{color: '#fff'}}>now</span></h2>
          <h4 data-aos="fade-up" data-aos-delay="50">Accessible and transparent, $BCC is available on leading platforms for your convenience.</h4>
          <h5 data-aos="fade-up" data-aos-delay="100">Available on:</h5>
          <div className='options'>
            <div className='option' data-aos="zoom-out">
              <img src={tronscan} alt='tronscan' width={115}/>
              <button className='more' onClick={tronscan_redirect}>
                <img src={more} alt='babycoconutchicken'/>
              </button>
            </div>
            <div className='option' data-aos="zoom-out" data-aos-delay="100">
              <img src={avedex} alt='avedex' width={115}/>
              <button className='more' onClick={avedex_redirect}>
                <img src={more} alt='babycoconutchicken'/>
              </button>
            </div>
            <div className='option' data-aos="zoom-out">
              <img src={dex} alt='dex' width={115}/>
              <button className='more' onClick={dex_redirect}>
                <img src={more} alt='babycoconutchicken'/>
              </button>
            </div>
          </div>
        </div>
        <div className='chicken-ufo' onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <animated.img className='chickenufo' src={chicken} alt='babycoconutchicken' style={{ transform: props.xy.interpolate(trans) }}/>
          <animated.img className='ufo' src={ufo} alt='babycoconutchicken' style={{ transform: props.xy.interpolate(trans2) }}/>
        </div>        
      </div>

    </div>
  );
}

export default Available;
