import React, { useEffect } from 'react';
import AOS from 'aos';
import { useSpring, animated } from 'react-spring';

import './styles.css';
import bePartOfOurStory from './img/Be-Part-of-Our-Story.png'
import chicken1 from './img/chicken1.png'
import chicken2 from './img/chicken2.png'


function Story() {

  const [props, set] = useSpring(() => ({
    xy: [0, 0], // domyślne wartości dla ruchu myszki
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans2 = (x, y) => `translate3d(${x / -90}px,${y / 70}px,0)`;
  const trans3 = (x, y) => `translate3d(${x / 70}px,${y / 70}px,0)`;


  const clouds = (x, y) => `translate3d(${x / -90}px,${y / -90}px,0)`;
  const front22 = (x, y) => `translate3d(${x / 90}px,${y / 90}px,0)`;
  const front11 = (x, y) => `translate3d(${x / -50}px,${y / -50}px,0)`;



  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="story">
      <div className='scene' onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
        <img 
          // style={{ transform: props.xy.interpolate(trans) }} 
          className='be-part' 
          src={bePartOfOurStory} 
          alt='Be Part of Our Story'
          data-aos="fade-up" data-aos-delay="600"
        />
        <animated.img 
          style={{ transform: props.xy.interpolate(trans2) }} 
          className='chicken1' 
          src={chicken1} 
          alt='babycoconutchicken'
        />
        <animated.img 
          style={{ transform: props.xy.interpolate(trans3) }} 
          className='chicken2' 
          src={chicken2} 
          alt='babycoconutchicken'
        />

        <animated.div 
          style={{ transform: props.xy.interpolate(front11) }} 
          className='front' 
          alt='babycoconutchicken'
        />
        <animated.div 
          style={{ transform: props.xy.interpolate(front22) }} 
          className='front2' 
          alt='babycoconutchicken'
        />
        <animated.div 
          style={{ transform: props.xy.interpolate(clouds) }} 
          className='back' 
          alt='babycoconutchicken'
        />
        
      </div>
      <div className='story-content'>
        <h3 data-aos="fade-up" data-aos-delay="0">Be Part of Our Story</h3>
        <h5 data-aos="fade-up" data-aos-delay="100">Baby Coconut Chicken isn't just a token - it's a journey, a story, and a family. </h5>
        <p data-aos="fade-up" data-aos-delay="200">As the proud baby of Coconut Chicken $CCC that is CEO of meme tokens on TRON, acclaimed pioneer in the meme token space. $BCC inherits a legacy of humor, heart, and the spirit of the crypto community.</p>
        <div data-aos="fade-up" data-aos-delay="300" className='story-li'>
          <div className='story-item'>
            <div className='point'>1.</div>
            <div className='txt'>
              Adopt a Chick Grab a TRON wallet (TRONLINK) and welcome $BCC into your crypto family by using chickenswap.com
            </div>
          </div>
          <div className='story-item'>
            <div className='point'>2.</div>
            <div className='txt'>
              Engage with our community, share the laughter, and watch as we take flight on the wings of blockchain technology. Join Telegram and Twitter!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Story;
