import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { useSpring, animated } from 'react-spring';
import { FaRegCopy } from "react-icons/fa";

import 'aos/dist/aos.css';
import './styles.css';

import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import chicken from './img/available-chicken.png'

function TokenSum() {
  const [textToCopy, setTextToCopy] = useState();

  const { number } = useSpring({
    from: { number: 0 },
    to: async (next) => {
      await next({ number: 10000000000 });
    },
    config: { duration: 1000, easing: t => t }, // Przykładowy efekt ease (liniowy)
  });
  const [props, set] = useSpring(() => ({
    xy: [0, 0], // domyślne wartości dla ruchu myszki
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans = (x, y) => `translate3d(${x / 120}px,${y / 120}px,0)`;

  const formattedNumber = number.interpolate(val => val.toLocaleString('en-US', { maximumFractionDigits: 2 }));
  const copyText = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('Tekst został skopiowany');
      })
      .catch((err) => {
        console.error('Błąd kopiowania tekstu: ', err);
      });
  };

  useEffect(() => {
    setTextToCopy('TXZUmRx4T1RW2Uj1GeTmJWyx98R9XAS2sn')
    AOS.init();
  }, []);


  return (

      <div className="token-sums" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
        <animated.img 
          style={{ transform: props.xy.interpolate(trans) }}
          className='chicken'
          src={chicken} 
          alt='babycoconutchicken'
        ></animated.img>
        <div className="token-sums-content">
          <div className='icon-1'>
            <img
              src={icon1} 
              alt={'babycoconutchicken'} 
              data-aos="fade-up"
              data-aos-delay="100"  
            /> 
            <animated.h3>{formattedNumber}</animated.h3>
            <h4>Total Supply</h4>      
          </div>
          <div className='icon-2'>
            <img
              src={icon2} 
              alt={'babycoconutchicken'}
              data-aos="fade-up"
              data-aos-delay="200"
            />
            <animated.h3>{formattedNumber}</animated.h3>
            <h4>Circulation</h4> 
          </div>
          <div className='icon-3'>
            <img
              src={icon3} 
              alt={'babycoconutchicken'}
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <h3>
              TXZUmRx4T1RW2Uj1GeTmJWyx98R9XAS2sn 
              <button
                className='copy'
                onClick={()=>{
                  copyText()
                }}
              >
                <FaRegCopy />
              </button>
            </h3>
            <h4>Contract Address</h4> 
          </div>
        </div>
      </div>    

  );
}

export default TokenSum;
