import React from 'react';
import 'aos/dist/aos.css';
import './styles.css';

function BuyHere() {

  const redirect = () => {
    window.open('https://www.chickenswap.com', '_blank');
  };

  return (
    <div className='buy-here'>
      Buy here
      <button
        onClick={redirect}
      >
        chickenswap.com
      </button>
    </div>    
  );
}

export default BuyHere;
