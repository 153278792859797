import React, { useEffect } from 'react';
import AOS from 'aos';

import 'aos/dist/aos.css';
import './styles.css';

import justin1 from './img/justin1.png'
import justin2 from './img/justin2.png'
import justin3 from './img/justin3.png'

function Justin() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="justin">
      <img
        className='justin-txt-1'
        src={justin1} 
        alt={'babycoconutchicken'} 
        data-aos="fade-up"   
      />
      <img
        className='justin-txt-2'
        src={justin2} 
        alt={'babycoconutchicken'}
        data-aos="zoom-out"
        data-aos-delay="300"
      />
      <img
        className='justin-txt-3'
        src={justin3} 
        alt={'babycoconutchicken'}
        data-aos="fade-down"
        data-aos-delay="100"
      />
    </div>
  );
}

export default Justin;
