import './styles.css';
import Hero from './sections/hero/Hero';
import Header from './sections/header/Header';
import Justin from './sections/justin/Justin';
import Token from './sections/token_detail/Token';
import TokenSum from './sections/token_sums/TokenSum';
import BuyHere from './sections/buy_here/BuyHere';
import Available from './sections/avaible/Available';
import Guide from './sections/guide/Guide';
import Story from './sections/story/Story';
import Footer from './sections/footer/Footer';

function App() {
  return (
    <div className="App">
      <Hero/>
      <Header/>
      <Justin/>
      <Token/>
      <TokenSum/>
      <BuyHere/>
      <Available/>
      <Guide/>
      <Story/>
      <Footer/>
    </div>
  );
}

export default App;
