import React, { useEffect } from 'react';
import AOS from 'aos';
import { useSpring, animated } from 'react-spring';

import './styles.css';
import chicken from './img/chicken.png'
import more from './img/more.png'



function Guide() {

  const mobile_redirect = () => {
    window.open('https://buy.coconutchicken.com/chickenswap-mobile.html', '_blank');
  };

  const desktop_redirect = () => {
    window.open('https://buy.coconutchicken.com/chickenswap-desktop.html', '_blank');
  };

  const [props, set] = useSpring(() => ({
    xy: [0, 0], // domyślne wartości dla ruchu myszki
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`;

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <div id='howtobuy' className="guide">

      <div className='guide-chickens' onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
        <animated.img src={chicken} className='guide-chickens-img' alt='babycoconutchicken' style={{ transform: props.xy.interpolate(trans), maxWidth: '550px', marginTop: '-5vh' }}/>
      </div>

      <div className='info'>
        <h2 data-aos="fade-up" data-aos-delay="0">How to buy <span style={{color: '#fdc348'}}>on ChickenSwap.com?</span></h2>
        <h4 data-aos="fade-up" data-aos-delay="50">Unlock the secrets of purchasing on ChickenSwap.com - Your guide awaits to dive into the thrilling world of cryptocurrencies!</h4>
        <div data-aos="fade-up" data-aos-delay="100" className='options'>
          <div className='option'>
            <button className='more' onClick={mobile_redirect}>
              Mobile
              <img src={more} alt='babycoconutchicken'/>
            </button>
          </div>
          <div className='option'>
            <button className='more' onClick={desktop_redirect}>
              Desktop
              <img src={more} alt='babycoconutchicken'/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guide;
